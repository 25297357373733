import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { ensureUser } from '../../util/data';
import { connect } from 'react-redux';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayPrice,
} from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  STOCK_MULTIPLE_ITEMS,
  STOCK_INFINITE_MULTIPLE_ITEMS,
  USER_TYPE_BUSINESS,
  FREE_PLAN_PRICE_LIMIT,
  SUBSCRIPTION_MEMBER_PLAN,
  MEMBER_PLAN_PRICE_LIMIT,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { createSlug, parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import {
  ModalInMobile,
  PrimaryButton,
  AvatarSmall,
  H1,
  H2,
  Reviews,
  Modal,
  Button,
} from '../../components';
import StarRatings from 'react-star-ratings';
import css from './OrderPanel.module.css';
import IconCollection from '../IconCollection/IconCollection';
import { checkCountLimit, checkPriceLimit, getLabel } from '../../util/dataExtractor';
import { listingConditions, listingSubCategories } from '../../config/configListing';
import { types as sdkTypes } from '../../util/sdkLoader';

import aa from 'search-insights';
import { reportUserEmailToAdmin } from '../../util/api';
import { EmailShare, FacebookShareButton, TwitterShareButton } from '../shareIcons';
import AuctionOrderForm from './AuctionOrderForm/AuctionOrderForm';
// import ReffralModal from '../../containers/PageBuilder/model';

import TokensAndProtectionCard from './TokensAndProtectionCard';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();
const url = process.env.REACT_APP_MARKETPLACE_ROOT_URL;

const { UUID, Money } = sdkTypes;

aa('init', {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
});

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location,
  isAuction
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.

  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price: formatPrice = {},
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
  } = props;

  const { listingType, unitType } = publicData || {};
  const isAuction = listingType == 'auction-product';
  const { amount, currency } =
    isAuction && publicData.currentBid ? publicData.currentBid : formatPrice || {};
  const price = new Money(amount, currency);
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const calculateAverage = reviews => {
  if (reviews?.length === 0) {
    return 0;
  }
  const sum =
    reviews &&
    reviews?.reduce((accumulator, currentValue) => accumulator + currentValue.attributes.rating, 0);
  const average = sum / reviews?.length;
  return average;
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    handleAuthorModal,
    reviews,
    handleCartItems,
    index,
    handleMakeAnOfferModal,
    handleMakeABidModal,
    authorListings,
    currentUser,
    makeABidForm,
    isAuthenticated
  } = props;
  const queryId = typeof window !== 'undefined' && window.localStorage.getItem('queryId');
  // const [isModalOpens, setIsModalOpens] = useState(false);

  const ensuredUser = ensureUser(currentUser);
  const { rewardsWallet: stokens = 0 } = ensuredUser?.attributes?.profile?.publicData ?? {};

  // useEffect(() => {
  //   if (authorListings == 1) {
  //     setIsModalOpens(true);
  //   }
  // }, [authorListings]);
  // const closeModals = () => {
  //   setIsModalOpens(false);
  // };
  const listingId = listing?.id?.uuid ?? null;
  const listingTitle = listing?.attributes?.title ?? null;

  const { email } = currentUser?.attributes || {};

  const handleAddToCart = () => {
    const { listing } = props;
    const { price } = listing;

    try {
      if (queryId) {
        aa('convertedObjectIDsAfterSearch', {
          userToken: currentUser?.id?.uuid, // Replace with dynamic user token if needed
          eventName: 'Product Added to Cart after Search',
          index: 'Stoado-DEV', // Replace with the actual index name
          objectIDs: [listing?.id?.uuid],
          queryId: queryId,
        });

        // Clear queryId from localStorage
        if (typeof window !== 'undefined') {
          window.localStorage.removeItem('queryId');
        }
      } else {
        aa('convertedObjectIDs', {
          userToken: currentUser?.id?.uuid, // Replace with dynamic user token if needed
          eventName: 'Product Added to Cart',
          index: 'Stoado-DEV', // Replace with the actual index name
          objectIDs: [listing?.id?.uuid],
        });
      }

      // Add to cart logic here
      // For example:
      // handleCartItems();
    } catch (error) {
      console.error('Error triggering analytics event:', error);
    }

    // Add to cart logic here
    // For example:
    // handleCartItems();
  };

  const publicData = listing?.attributes?.publicData || {};
  const {
    listingType,
    unitType,
    transactionProcessAlias = '',
    subCategory,
    condition,
    receiveOffers,
  } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const price = listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  const showPriceMissing = isPaymentProcess && !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';

  const isAuction = listingType == 'auction-product';
  const showAuctionOrderForm = isAuction && typeof currentStock === 'number';

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const allowOrdersOfMultipleItems = [STOCK_MULTIPLE_ITEMS, STOCK_INFINITE_MULTIPLE_ITEMS].includes(
    listingTypeConfig?.stockType
  );

  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');
  const {
    userName,
    userType,
    listingsSoldPrice,
    listingsSoldCount,
    currentPlanData,
    freePlanData,
    businessListingUnavailable,
  } = (!!author?.id && author.attributes.profile.publicData) || {};

  const isExceedPriceLimit = checkPriceLimit(listingsSoldPrice, freePlanData, currentPlanData);
  const isExceedCountLimit = checkCountLimit(listingsSoldCount, freePlanData, currentPlanData);

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const hasStock = currentStock && currentStock > 0;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setDescription] = useState('');

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleShareClick = async () => {
    try {
      const shareContent = url + location?.pathname; // Replace with actual content
      await navigator.clipboard.writeText(shareContent);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000); // Reset copied state after 2 seconds
    } catch (error) {
      console.error('Failed to copy to clipboard:', error);
      // Handle error if necessary
    }
  };

  const onHandleSubmit = event => {
    if (event) {
      reportUserEmailToAdmin({
        reporterUserId: currentUser?.id?.uuid,
        sellerUserId: listing?.author?.id?.uuid,
        listingId: listing?.id?.uuid,
        textBox: description,
        reporterUserName: currentUser?.attributes?.profile?.publicData?.userName,
        reportedUserName: listing?.author?.attributes?.profile?.publicData?.userName,
        email: email,
      });
    }
  };

  const totalLimit = freePlanData?.isFreePlanActive
    ? FREE_PLAN_PRICE_LIMIT
    : currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN
      ? MEMBER_PLAN_PRICE_LIMIT
      : null;
  const isFreePlanActive = freePlanData?.isFreePlanActive;
  const isSubscriptionMember = currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN;
  const remainingLimit = totalLimit - listingsSoldPrice;
  const itemPrice = price?.amount / 100;

  const [isModalOpenShare, setIsModalOpenShare] = useState(false);
  const slug = createSlug(listingTitle);

  let urlShare = `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/l/${slug}/${listingId}`;

  const closeModalShare = () => {
    setIsModalOpenShare(false);
  };

  const hasExceededLimits = isExceedPriceLimit || isExceedCountLimit;
  const isPlanInactive = !currentPlanData?.isActive && !freePlanData?.isFreePlanActive;
  const hasBusinessListingIssue = businessListingUnavailable;
  const isUnderLimit =
    (isFreePlanActive || isSubscriptionMember) && totalLimit && remainingLimit < itemPrice;
  const shouldHideButtonGroup =
    hasExceededLimits || isPlanInactive || hasBusinessListingIssue || isUnderLimit;

  const showAddToCartButton = !isAuction && hasStock && !isOwnListing;
  const isInCart = index > -1;
  const addToCartButtonText = isInCart ? (
    <FormattedMessage id="OrderPanel.removeToCart" />
  ) : (
    <FormattedMessage id="OrderPanel.addToCart" />
  );
  const addToCartButtonClassName = showAddToCartButton
    ? classNames(isInCart ? css.smallFont : null, css.addToCartButton)
    : css.addToCartButtonDisable;

  const handleAddToCartButtonClick = showAddToCartButton
    ? () => {
      handleCartItems();
      handleAddToCart();
    }
    : undefined;

  const hasActivePlan = isFreePlanActive || isSubscriptionMember;
  const hasLimit = totalLimit > 0;
  const exceedsLimit = remainingLimit < itemPrice;
  const isOfferButtonDisabled = hasActivePlan && hasLimit && exceedsLimit;

  const canMakeAnOffer = receiveOffers === 'yes' && hasStock && !isOwnListing;
  const offerButtonClass = isOfferButtonDisabled
    ? css.makeAnOfferButtonDisable
    : classNames(index > -1 ? css.smallFont : null, css.makeAnOfferButton);
  const offerButtonClickHandler = isOfferButtonDisabled ? null : handleMakeAnOfferModal;

  function calcStokensFromPrice() {
    function mapValue(value, inMin, inMax, outMin, outMax) {
      return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    }
    const amount = Math.floor(price.amount / 100);

    const customerPlanName =
      publicData?.freePlanData?.planName || publicData?.currentPlanData?.planName || 'Free-Plan';

    switch (customerPlanName) {
      case 'business-member':
      case 'business-enterprise':
        if (amount >= 0 && amount <= 20) {
          return 45;
        } else if (amount >= 21 && amount <= 500) {
          return 100;
        } else if (amount >= 501 && amount <= 1000) {
          return 150;
        } else {
          return 150;
        }
      default:
        if (amount >= 0 && amount <= 20) {
          return 5;
        } else if (amount >= 21 && amount <= 500) {
          return 15;
        } else if (amount >= 501) {
          return 50;
        } else {
          return 50;
        }
    }
  }
  const calculatedStokens = calcStokensFromPrice();

  return (
    <div className={classes}>
      <div className={css.sideBarButtonGroup}>
        {isAuthenticated && !isOwnListing && (
          <>
            <div>
              <span className={css.reportUser} onClick={openModal}>
                <FormattedMessage id="OrderPanel.reportUser" />
              </span>
              <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                onManageDisableScrolling={onManageDisableScrolling}
                contentLabel="Report User"
                className={css.reportUserModal}
              >
                <h2 className={css.reportUserTitle}>Report This User</h2>
                <form onSubmit={onHandleSubmit}>
                  <label className={css.label}>Your reason for reporting:</label>
                  <textarea
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="Enter your description"
                    required
                  />
                  <div className={css.reportText}>
                    We take reporting seriously and will review the situation ASAP.
                  </div>
                  <div>
                    <Button type="submit" className={css.ReportSubmitbutton}>
                      Report User
                    </Button>
                  </div>
                </form>
              </Modal>
            </div>{' '}
            <span
              onClick={() => {
                if (!isOwnListing) {
                  handleAuthorModal();
                }
              }}
              className={css.messageSeller}
            >
              <IconCollection icon="messageIcon" />
              <FormattedMessage id="OrderPanel.messageSeller" />
            </span>{' '}
          </>
        )}
        <button className={css.productShare} onClick={() => setIsModalOpenShare(true)}>
          <IconCollection icon="shareIcon" />
          <FormattedMessage id="OrderPanel.share" />
        </button>{' '}
        {/* Modal */}
        {isModalOpenShare && (
          <div className={css.modalOverlay}>
            <div className={css.modal}>
              <div className={css.modalContent}>
                <h2 className={css.modalTitle}>
                  <IconCollection icon="ICON_MODAL_SHARE" /> Share This Profile
                </h2>
                <div className={css.shareContent}>
                  <span className={css.close} onClick={closeModalShare}>
                    <IconCollection icon="ICON_MODAL_CLOSE" />
                  </span>
                  <div className={css.icons}>
                    <span>
                      <FacebookShareButton url={urlShare} quote={urlShare} />
                    </span>
                    <span className={css.socialIcon}>
                      <TwitterShareButton url={urlShare} text={urlShare} />
                    </span>
                    <span className={css.socialIcon}>
                      <EmailShare url={urlShare} />
                    </span>
                  </div>

                  <p className={css.linkCopied}>{isCopied ? 'Thank you for sharing!' : null}</p>

                  <Button className={css.linkIconButton} onClick={handleShareClick}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M10.0381 14.0385C11.6339 12.4492 14.417 12.4492 16.0127 14.0385L17.0085 15.0303L19 13.0468L18.0042 12.055C16.6761 10.7308 14.9071 10 13.0254 10C11.1438 10 9.37476 10.7308 8.0466 12.055L5.0579 15.0303C3.74002 16.3471 3 18.1305 3 19.9897C3 21.849 3.74002 23.6324 5.0579 24.9492C5.71124 25.6008 6.48742 26.1174 7.34185 26.4694C8.19627 26.8213 9.1121 27.0016 10.0367 27C10.9616 27.0019 11.8777 26.8217 12.7324 26.4697C13.5871 26.1178 14.3635 25.601 15.0169 24.9492L16.0127 23.9574L14.0212 21.9739L13.0254 22.9657C12.2317 23.7526 11.1574 24.1944 10.0374 24.1944C8.91748 24.1944 7.84312 23.7526 7.04943 22.9657C6.2586 22.1755 5.81452 21.1054 5.81452 19.9897C5.81452 18.8741 6.2586 17.8039 7.04943 17.0138L10.0381 14.0385Z"
                        fill="white"
                      ></path>
                      <path
                        d="M14.9831 5.05077L13.9873 6.04252L15.9788 8.02603L16.9746 7.03427C17.7683 6.24731 18.8426 5.8055 19.9626 5.8055C21.0825 5.8055 22.1569 6.24731 22.9506 7.03427C23.7414 7.82441 24.1855 8.89457 24.1855 10.0102C24.1855 11.1259 23.7414 12.1961 22.9506 12.9862L19.9619 15.9614C18.3661 17.5508 15.583 17.5508 13.9873 15.9614L12.9915 14.9697L11 16.9532L11.9958 17.945C13.3239 19.2692 15.0929 20 16.9746 20C18.8562 20 20.6252 19.2692 21.9534 17.945L24.9421 14.9697C26.26 13.6529 27 11.8695 27 10.0102C27 8.15099 26.26 6.36756 24.9421 5.05077C23.6203 3.73751 21.8296 3 19.9626 3C18.0956 3 16.3048 3.73751 14.9831 5.05077Z"
                        fill="white"
                      ></path>
                    </svg>
                    Copy The Profile URL
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.heading}>{title}</h1>
        </div>
        <div className={css.orderSection}>
          <div className={css.orderHeading}>
            {titleDesktop ? titleDesktop : <h2 className={titleClasses}>{title}</h2>}
            {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
          </div>
          <div className={css.priceAndQuantity}>
            <PriceMaybe
              price={price}
              publicData={publicData}
              validListingTypes={validListingTypes}
              intl={intl}
              marketplaceCurrency={marketplaceCurrency}
            />
            <span>| </span>
            <FormattedMessage id="OrderPanel.quantity" values={{ currentStock }} />
          </div>
          <div className={css.siderCategoryGroup}>
            <span
              onClick={() => {
                history.push(`/s?refinementList=${subCategory}`);
              }}
            >
              {getLabel(listingSubCategories, subCategory)}
            </span>{' '}
            <span>{getLabel(listingConditions, condition)}</span>{' '}
          </div>
        </div>
        <div className={css.author}>
          <div className={css.providerAvatarWrapper}>
            <AvatarSmall user={author} className={css.providerAvatar} />
            {userType === USER_TYPE_BUSINESS && (
              <span className={css.badge}>
                <IconCollection icon="badgeIcon" />
              </span>
            )}
          </div>
          <div className={css.authorInfo}>
            <div>
              <span className={css.providerNameLinked}>
                <FormattedMessage
                  id="OrderPanel.author"
                  values={{ name: userName || authorLink }}
                />
              </span>
              <span className={css.providerNamePlain}>
                <FormattedMessage
                  id="OrderPanel.author"
                  values={{ name: userName || authorDisplayName }}
                />
              </span>
            </div>
            <div className={css.reviewRattingContainer}>
              <StarRatings
                svgIconViewBox="0 0 40 37"
                svgIconPath="M20 0L26.113 11.5862L39.0211 13.8197L29.891 23.2138L31.7557 36.1803L20 30.4L8.2443 36.1803L10.109 23.2138L0.97887 13.8197L13.887 11.5862L20 0Z"
                starRatedColor="#ffb802"
                rating={reviews?.length ? calculateAverage(reviews) : 0}
                starDimension="15px"
                starSpacing="0px"
              />
              <span className={css.reviewsListingContainer}>
                <FormattedMessage id="OrderPanel.review" values={{ reviews: reviews?.length }} />
                &nbsp;|&nbsp;
                <FormattedMessage id="OrderPanel.listings" values={{ listing: authorListings }} />
              </span>
            </div>
          </div>
        </div>
        {showPriceMissing ? (
          <PriceMissing />
        ) : showInvalidCurrency ? (
          <InvalidCurrency />
        ) : showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="OrderPanelBookingTimeForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
          />
        ) : showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
          />
        ) : showAuctionOrderForm ? (
          <AuctionOrderForm
            formId="OrderPanelAuctionOrderForm"
            onSubmit={handleMakeABidModal}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            currentStock={currentStock}
            allowOrdersOfMultipleItems={allowOrdersOfMultipleItems}
            pickupEnabled={pickupEnabled && displayPickup}
            shippingEnabled={shippingEnabled && displayShipping}
            displayDeliveryMethod={displayPickup || displayShipping}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
            listing={listing}
            makeABidForm={makeABidForm}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        ) : showProductOrderForm ? (
          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            currentStock={currentStock}
            allowOrdersOfMultipleItems={allowOrdersOfMultipleItems}
            pickupEnabled={pickupEnabled && displayPickup}
            shippingEnabled={shippingEnabled && displayShipping}
            displayDeliveryMethod={displayPickup || displayShipping}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
            listing={listing}
          />
        ) : showInquiryForm ? (
          <InquiryWithoutPaymentForm formId="OrderPanelInquiryForm" onSubmit={onSubmit} />
        ) : !isKnownProcess ? (
          <p className={css.errorSidebar}>
            <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
          </p>
        ) : null}
        {shouldHideButtonGroup ? null : (
          <div className={css.sideBarCardButtonGroup}>
            {/* Add to Cart Button */}
            <span onClick={handleAddToCartButtonClick} className={addToCartButtonClassName}>
              {addToCartButtonText}
            </span>

            {/* Make an Offer Button */}
            {(isOfferButtonDisabled || canMakeAnOffer) && (
              <span onClick={offerButtonClickHandler} className={offerButtonClass}>
                <FormattedMessage id="OrderPanel.makeAnOffer" />
              </span>
            )}
          </div>
        )}
        <div className={css.logos}>
          <IconCollection icon="ICON_GOOGLEPAY" />
          <IconCollection icon="ICON_VISA" />
          <IconCollection icon="ICON_MASTERCARD" />
          <IconCollection icon="ICON_AMEX" />
          <IconCollection icon="ICON_BITCOIN" />
          <IconCollection icon="ICON_APPLEPAY" />
        </div>

        <TokensAndProtectionCard
          icon="pointsIcon"
          title="ProductOrderForm.stoadoPointsTitle"
          description={{
            id: 'ProductOrderForm.stoadoPointsDescription',
            values: {
              stokens,
            },
          }}
        />
        <TokensAndProtectionCard
          icon="stoadoIcon"
          title="ProductOrderForm.purchaseBenefitsTitle"
          description={{
            id: 'ProductOrderForm.purchaseBenefitsDescription',
            values: {
              stokens: calculatedStokens,
            },
          }}
        />
        <TokensAndProtectionCard
          icon="verifiedIcon"
          title="ProductOrderForm.stoadoVerifiedTitle"
          description={{
            id: 'ProductOrderForm.stoadoVerifiedDescription',
          }}
        />
      </ModalInMobile>
      <div className={css.openOrderForm}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          showCurrencyMismatch
        />

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <PrimaryButton
            onClick={handleSubmit(
              isOwnListing,
              isClosed,
              showInquiryForm,
              onSubmit,
              history,
              location,
              isAuction
            )}
            disabled={isOutOfStock}
            className={css.primaryBtn}
          >
            {isAuction ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageAuction" />
            ) : isBooking ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
            ) : isOutOfStock ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
            ) : isPurchase ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
            ) : (
              <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
            )}
          </PrimaryButton>
        )}
      </div>
      {/* <ReffralModal isOpen={isModalOpens} onClose={closeModals} /> */}
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  return {
    isAuthenticated,
  };
};

export default compose(withRouter, injectIntl, connect(mapStateToProps))(OrderPanel);
